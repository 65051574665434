import { render, staticRenderFns } from "./CameraOnIdentityCardKyc.vue?vue&type=template&id=338b8ef2&scoped=true&"
import script from "./CameraOnIdentityCardKyc.vue?vue&type=script&lang=js&"
export * from "./CameraOnIdentityCardKyc.vue?vue&type=script&lang=js&"
import style0 from "./CameraOnIdentityCardKyc.vue?vue&type=style&index=0&id=338b8ef2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338b8ef2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VImg,VTextField})
