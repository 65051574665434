<template>
  
  <div align="center" class="grey-background">
    <div v-if="isCameraOpen" v-show="!isLoading" :class="{ 'flash' : isShotPhoto }">
      <br>
      <p v-if="isCameraOpen && !isLoading && !isPhotoTaken"  class="paragraph">{{$t('TitleCameraOnIdentityCard')}}</p>
      <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
      <video v-show="!isPhotoTaken" ref="camera" class="camera-stream" :width="250" :height="337.5" autoplay>
      </video>
      <br>
      <p>{{$t('Camera2times')}}</p>
    </div>
  
    <div v-if="isCameraOpen && !isLoading && !isPhotoTaken" 
      class="camera-shoot">
      <v-img 
        class="camera-img-capture"
        width="80px"
        height="80px"  
      :src="require('../../assets/kyc/buttonCamera.svg')"
      @click="takePhoto()">
      </v-img>

      <v-img
        class="camera-img-split"
        width="40px"
        height="40px"  
        :src="require('../../assets/kyc/camera-back.svg')"
        @click="frontCamera()">
        </v-img>
    </div>

  <div v-show="isPhotoTaken" align="center" class="white-background">

      <notification-popup 
      :dialog="notifFailed"
      :contentMsg="$t('failed')"
      :headerMsg="$t('FailedSubmit')"
      :nameBtnNo="$t('labelNo')"
      :nameBtnYes="$t('labelYes')"
      colorIcon = "danger"
      typeModal="transaction"
      Icon="mdi-close-circle"
      :onHandlerNo="cancelFailed"
      :onHandlerYes="buttonOkeFailed"/>


    <br><br>
    <div v-show="isPhotoTaken" >
      <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="250" :height="337.5"></canvas>
      <div>
        <v-btn
        @click="takePhoto"
            color="primary"
            small
            dark
            text
          >
          {{$t('RepeatPhoto')}}
        </v-btn>
      </div>
    </div>
    
    <div class="school-information" v-if="isPhotoTaken && isCameraOpen" >
      <h3>{{$t('SchoolInformation')}}</h3>

      <v-autocomplete
      v-model="school" 
      type="text"
      :items="arraySchool"
      item-text="school_name"
      item-value="school_id"
      :label="$t('School')"
      :rules="[rules.required]"
        outlined
      ></v-autocomplete>

      <v-text-field
      v-model="schoolId" 
      type="number"
      :label="$t('SchoolId')"
      :rules="[rules.required]"
        outlined
        ></v-text-field>
      
      <v-btn
      class="button-school-information"
      @click="Confirmation()"
          color="primary"
          normal
          dark
          rounded
        >
        {{$t('Confirmation')}}
      </v-btn>
    </div>
  </div>
</div>
</template>
<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'CameraOnIdentityCardKyc',
    components: { 
    NotificationPopup,
    // WebCam,
    },
    data() {
      return {
        school:"",
        email:"",
        schoolId:"",
        isCameraOpen: true,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        arraySchool: [],
        listCamera: [],
        frontCam: false,
        backCam: false,
        notifFailed:false,
        errorfrontCam: "",
        errorbackCam: "",
        dataResponse:"",
        id_card : this.$store.state.cdn.photo_identiti_card,
        rules: {
          required: (value) => !!value || "Required.",
          min: (v) => v.length >= 6 || "Min 6 characters",
          emailMatch: () => `The email and password you entered don't match`,
        },
      }
    },
    mounted(){
      this.isCameraOpen = true;
      this.backCamera();
      
      this.$store
        .dispatch("childlinkage/getListSchool")
        .then((response) => {
          this.arraySchool = response.data
        })
        .catch((err) => {
          console.log(err);
        });

    },
    methods:{
      cancelFailed(){
        this.notifFailed=false;
      },
      // cancelSuccess(){
      //   this.notifSuccess=false;
      // },
      backCamera(){

        this.isLoading = true;
      
        const constraints = (window.constraints = {
          audio: false,
          video:{facingMode:{exact:"environment"}}
        });
        
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.frontCamera()
          });
      },
      frontCamera(){
        this.isLoading = true;
          const constraints = (window.constraints = {
            audio: false,
            video : {facingMode:"user"}
          });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error)
            alert("May the browser didn't support or there is some errors.");
          });
      },
      b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 100;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);
        
            byteArrays.push(byteArray);
        }

       var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },
      Confirmation(){
        var canvas = document.getElementById("photoTaken");
        var img    = canvas.toDataURL("image/jpeg");
        var block = img.split(";");

        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

        // Convert it to a blob to upload
        var blob = this.b64toBlob(realData, contentType);

        // console.log('Encoded Binary File String:', blob);
        
        if (this.school == "" && this.schoolId ==""){
          this.notifFailed = true
        }

        let payload = {
          file: blob,
          filename: this.$store.state.auth.userData.user_name + "_id_card.jpg",
        }

        this.$store
        .dispatch("cdn/uploadPhotoIdentity", payload)
        .then((response) => {
          this.dataResponse =  response.photo
          // console.log("response upload", response)
        }).then(()=>{
          // console.log("response upload 2", this.dataResponse)
          if (this.dataResponse == ""){
            this.notifFailed = true
          }else{
          
          let payloadKyc = {
              school_id: this.school,
              student_idn: this.schoolId,
              kycp_photo_id_card: this.dataResponse
          }
          console.log("response upload", payloadKyc)

          this.$store
            .dispatch("passenger/registerKyc", payloadKyc)
            .then((resp) => {
              if (resp.status == true) {
                this.$router.push({
                  name: 'SelfieIdentityCard',
                })
              }
            })
            .catch((err) => {
              console.log(err);
            });
          }
        })
        .catch((err) => {
          this.notifFailed = true
          console.log(err);
        });

      },
      buttonOkeFailed(){
        this.notifFailed = false
      },
      // submitKyc(){
      //   if (this.id_card != "" && this.school != "" && this.schoolId !=""){
      //       this.notifFailed = true
      //   }
      //   let payloadKyc = {
      //       school_id: this.school,
      //       student_idn: this.schoolId,
      //       kycp_photo_id_card: this.id_card
      //   }

      //   this.$store
      //     .dispatch("passenger/registerKyc", payloadKyc)
      //     .then((resp) => {
      //       if (resp.status == true) {
      //       this.$router.push({
      //         name: 'SelfieIdentityCard',
      //       })
      //       }
      //     })
      //     .catch((err) => {
      //       this.notifFailed = true
      //       console.log(err);
      //     });
      // },
      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach(track => {
          track.stop();
        });
      },
      takePhoto() {
        if(!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }
        
        this.isPhotoTaken = !this.isPhotoTaken;
        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 250, 337.5);
      },
    },
  }
</script>

<style lang="scss" scoped>

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .camera-stream {
      width: 80%;
      max-height: 100%;
    // padding-right: 20px;
    // padding-left: 20px;

    margin:auto;
    padding: 7% 5%;
    background-image: url('~@/assets/assetsScanQr/frame-camera-img.svg');

    background-position: center;
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }

  .camera-shoot {
    position: fixed;
    left: 45%;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  

  }
  .camera-img-split{
    bottom: 0;
    right: -130px;
    margin-top: -45px;  
  }
  
  .school-information{
    width: 100%;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

 .button-school-information{
    width: 100%;
    color:#4BB14E;

  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .camera-stream {
      width: 80%;
      max-height: 100%;
    // padding-right: 20px;
    // padding-left: 20px;

    margin:auto;
    padding: 7% 5%;
    background-image: url('~@/assets/assetsScanQr/frame-camera-img.svg');

    background-position: center;
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }

  .camera-shoot {
    position: fixed;
    left: 170px;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  

  }
  .camera-img-split{
    bottom: 0;
    right: -130px;
    margin-top: -45px;  
  }
  
  .school-information{
    width: 100%;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

 .button-school-information{
    width: 100%;
    color:#4BB14E;

  }
}
</style>